<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <div class="home container pt-5" id="app">
                <div class="box">
                    <div class="grid grid-cols-4 gap-4">
                        <div class="col">
                            <label class="block">Date From</label>
                            <a-input type="date" class="form-control" v-model:value="dateFrom" />
                        </div>

                        <div class="col">
                            <label class="block">Date To</label>
                            <a-input type="date" class="form-control" v-model:value="dateTo" />
                        </div>
                        <div class="col">
                            <label class="block">User</label>
                            <a-select class="w-full mb-3" v-model:value="selectedUser">
                                <a-select-option :value="user.username" v-for="user in userList" :key="user.id">{{user.nicename}}</a-select-option>
                            </a-select>
                        </div>

                        <div class="col">
                            <label class="block invisible">Submit</label>
                            <a-button type="primary" block @click="getTasks">Submit</a-button>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="tasks.length > 0" class="mb-2">
                        <h3 class="text-lg font-bold">{{selectedUser}}</h3>
                        <h4 class="text-base font-semibold">AHT: {{getAht()}}</h4>
                        <h5 class="text-base font-semibold">Items: {{mainTasks.length}}</h5>
                    </div>
                    <table class="w-full border border-gray-200 rounded" v-if="tasks.length > 0">
                        <thead>
                            <tr class="border-b border-gray-200 bg-gray-50">
                                <th class="px-1 py-2 border-r border-gray-200">Ignore</th>
                                <th class="px-1 py-2 border-r border-gray-200">Order ID</th>
                                <th class="px-1 py-2 border-r border-gray-200">Start</th>
                                <th class="px-1 py-2 border-r border-gray-200">End</th>
                                <th class="px-1 py-2 border-r border-gray-200">Spent</th>
                                <th class="px-1 py-2 border-r border-gray-200">AHT</th>
                                <th class="px-1 py-2">Diff</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="task in tasks" :key="task.id" class="border-b border-gray-200">
                                <td class="px-1 py-1 border-r border-gray-200 text-center"><input type="checkbox" v-model="task.ignore"/></td>
                                <td class="px-1 py-1 border-r border-gray-200">{{task.notes.replace('orderID ','')}}</td>
                                <td class="px-1 py-1 border-r border-gray-200">{{dateFormat(task.picked)}}</td>
                                <td class="px-1 py-1 border-r border-gray-200">{{dateFormat(task.completed)}}</td>
                                <td class="px-1 py-1 border-r border-gray-200">{{timeSpent(task.picked, task.completed)}} mins</td>
                                <td class="px-1 py-1 border-r border-gray-200">{{secsToMins(task.aht)}}</td>
                                <td class="px-1 py-1" :class="{'is_under': ahtConvert(task) < 0, 'is_over': ahtConvert(task) > 0}">
                                    {{ahtConvert(task)}}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td :class="{'is_under': getTotalDiff() < 0, 'is_over': getTotalDiff() > 0}">
                                    {{getTotalDiff()}}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import moment from 'moment';
export default defineComponent({
    name: "Logs",
    data() {
        return {
            selectedUser: "BPUTT",
            userList: [],
            dateFrom: moment().format('YYYY-MM-DD'),
            dateTo: moment().format('YYYY-MM-DD'),
            gettingTasks: true,
            tasks: [],
        }
    },
    computed: {
        mainTasks(){
            return this.tasks.filter(el => el.ignore === false || el.ignore == null);
        }
    },
    mounted() {
        this.getUsers();
    },
    methods:{
        getAht() {
            let time = 0;
            this.mainTasks.forEach(task => {
                time += this.timeSpent(task.picked, task.completed);
            });
            return (time / this.mainTasks.length).toFixed(2);
        },
        timeSpent(start,end) {
            const date1 = moment(end)
            const date2 = moment(start)
            return date1.diff(date2, 'm')
        },
        getTasks(){
            this.$http.get('https://api.darkside-developments.com/api/crm/dispatch/logs?dateFrom='+this.dateFrom+'&dateTo='+this.dateTo+'&user=' + this.selectedUser).then(resp => {
                this.tasks = resp.data;
            });
        },
        getUsers() {
            this.$http.get('https://api.darkside-developments.com/api/users/all').then((resp) => {
                resp.data.forEach(element => {
                    element.username = element.username.toUpperCase();
                    this.userList.push(element);
                });
            })
        },
        dateFormat(val) {
            return moment(val).format('DD-MM-YYYY H:mm:ss')
        },
        secsToMins(val) {
            if(!val) return;
            return (val / 60).toFixed(2) + " mins";
        },
        ahtConvert(task) {
            if(task.aht) {
                const diff = task.taken - task.aht
                if(task.taken > task.aht) {
                    task.diff = (diff / 60).toFixed(2)
                }
                if(task.taken < task.aht) {
                    task.diff = (diff / 60).toFixed(2)
                }
                return task.diff
            }
            return null;
        },
        getTotalDiff() {
            let aht = 0;
            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                if(task.diff !== undefined) {
                    aht += parseFloat(task.diff);
                }
            }
            return aht.toFixed(2);
        }
    },
    watch: {
        selectedUser() {
            this.tasks = [];//this.getTasks();
        }
    }
});
</script>

<style lang="scss" scoped>
.box {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
}
td.is_under {
    color: green
}
td.is_over {
    color: red;
}
</style>
